import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"

import { mq } from "../utils/media-queries"
import Layout from "../components/Layout"
import VideoCategoriesSection2 from "../components/VideoCategoriesSection2"

export default () => {
  const [videoPage, handleVideoPage] = useState(0)
  return (
    <Layout
      title='Hodgkin Lymphoma Patient Video Stories | Hodgkin Hub'
      description='Watch videos from patients and caregivers about diagnosis, making treatment decisions, and preparing for Hodgkin Lymphoma treatment.'
      canonicalURL='https://hodgkinhub.com/videos/'
      OGTitle='Hodgkin Lymphoma Patient Video Stories'
      OGDescription='Watch videos from patients and caregivers about diagnosis, making treatment decisions, and preparing for Hodgkin Lymphoma treatment.'
      OGImage='https://hodgkinhub.com/static/header-logo-02c93b151e6a0b1d545c321f517bafd0.png'
      OGUrl='https://hodgkinhub.com/videos/'
      OGType='Website'
    >
      <Container>
        <Row>
          <Col
            css={css`
              padding: 5px 15px 5px;
              ${[mq[2]]} {
                padding: 24px 15px;
              }
            `}
          >
            <h1
              id='slide-to-video-top'
              css={css`
                color: #69696a;
                font-size: 45px;
                font-weight: bold;
                line-height: 45px;
                text-align: center;
                text-transform: uppercase;
              `}
            >
            </h1>
          </Col>
        </Row>

        <Row>
          <VideoCategoriesSection2
            videoPage={videoPage}
            handleVideoPage={handleVideoPage}
          />
        </Row>
      </Container>
    </Layout>
  )
}
