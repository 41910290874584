import React from "react"
import { Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"

import { mq } from "../utils/media-queries"

const VideoSubheader = props => {
  return (
    <Row>
      <Col
        css={css`
          background: linear-gradient(180deg, #01AEF1 0%, #02C8F6 100%);
          box-shadow: 2px 3px 4px 0 rgba(0,0,0,0.35);
          font: 18px/25px Arial, sans-serif;
          color: white;
          padding 24px 20px;
          margin: 0 15px 20px;

          ${[mq[2]]} {
            font-size: 16px;
            line-height: 18px;
          }
        `}
      >
        <div
          id={props.id}
          style={{
            position: "absolute",
            top: "-185px",
          }}
        ></div>
        {props.subheader}
      </Col>
    </Row>
  )
}

export default VideoSubheader
