import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { css } from "@emotion/core"
import { useStateValue } from "../state/state"

import QuestionsToAsk from "./QuestionsToAsk"
import LearnAbout from "./about/LearnAbout"

import VideoCategory from "./VideoCategory"
import VideoSubheader from "./VideoSubheader"

function extractOrder(url) {
  let num = Number(url.split('-').pop().replace('/', ''));
  return num;
}

const VideosSection2 = (props) => {
  const { allContentfulVideoAsset } = useStaticQuery(
    graphql`
      query {
        allContentfulVideoAsset (sort: {fields: [ordering], order: ASC}) {
          edges  {
            node {
              id
              ordering
              urlSlug
              videoUrl
              tags
              title
              createdAt
              meta {
                alt
              }
              thumbnail {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    `
  )
  let [{ stagesTab }, dispatch] = useStateValue()
  stagesTab = "allVideos";
  //const [URLTabCalled, handleURLTabCalled] = useState(false);
  const { videoPage, handleVideoPage, isHome } = props;
  // const [ videoPage, handleVideoPage ] = useState(0)
  const dispatchFunc = (val) => {
    if (val === '') {
      return
    }
    dispatch({
      type: "handleStagesTab",
      stagesTab: val,
    })
  }

  // const handleNewVideoPage = (event) => {
  //   // const { name } = event.target;
  //   // if (name === 'arrow-right') {
  //   //   handleVideoPage(videoPage + 1)
  //   // }
  //   // if (name === 'arrow-left') {
  //   //   handleVideoPage(videoPage - 1)
  //   // }
  //   if (videoPage === 4) {
  //     handleVideoPage(0)
  //   } else {
  //     handleVideoPage(videoPage + 1)
  //   }
  //   // let slideToVideo = document.getElementById('slide-to-video-top');
  //   // slideToVideo.scrollIntoView({ behavior: 'smooth' });
  // }

  // const handleURLTab = () => {
  //   let path = window.location.href;
  //   let hash = path.split('#')[1];
  //   hash === 'Newly-Diagnosed' ? dispatchFunc('newlyDiagnosed') :
  //     hash === 'Making-Treatment-Decisions' ? dispatchFunc('makeTreatmentDecisions') :
  //       hash === 'Preparing-For-Treatment' ? dispatchFunc('prepForTreatment') :
  //         hash === 'Managing-Treatment' ? dispatchFunc('managingTreatment') :
  //           dispatchFunc('')

  //   // let slideToVideo = document.getElementById('slide-to-video-top');
  //   // if (slideToVideo) {
  //   //   slideToVideo.scrollIntoView({ behavior: 'smooth' });
  //   // }

  // }
  // useEffect(() => {
  //   if (URLTabCalled === false) {
  //     handleURLTab()
  //   }
  //   handleURLTabCalled(true)
  // })
  return (
    <Container css={css`
      h1 {
        color: #8C8D91;
        font-family: Arial;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 45px;
        text-align: center;
      }
  `}>

      <Row>
        <Col
          css={css`
                margin-bottom: 0px;
              `}
          lg={12}
        >
          <h1>VIDEO LIBRARY</h1>
          <VideoSubheader
            key={`home ${"All Videos"}`}
            subheader={"Hear from people with Hodgkin lymphoma on their personal experiences from diagnosis to treatment decisions and managing treatment."}
            id={"All-Videos"}
            isHome={isHome}
          />
        </Col>
      </Row>
      <Row>
        {allContentfulVideoAsset.edges
          .map(({ node }, i) => (
            <>
              <Col key={i}
                css={css`
                        margin-bottom: 100px;
                      `}
                xs={6}
                lg={3}
              >
                <div>
                  <VideoCategory
                    ordering={node.ordering}
                    key={`home ${node.tags[0]}`}
                    img={node.thumbnail.fluid.src}
                    category={node.title}
                    slug={node.urlSlug}
                    isHome={isHome}
                    alt={node.meta.alt}
                  />
                </div>
              </Col>
            </>
          )
          )}
      </Row>
      <QuestionsToAsk />
    </Container>
  )
}

// VideosSection.propTypes = {

// }

export default VideosSection2






