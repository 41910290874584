import React from "react"
import { css } from "@emotion/core"
import arrowRight from '../assets/images/video-arrow-white.svg'
import playBtn from '../assets/images/blue-play-btn.svg'
import PropTypes from "prop-types"

import { mq } from "../utils/media-queries"

const VideoCategory = ({ img, ordering, category, slug, isHome, alt }) => {
  return (
    <a
      id={`${isHome ? "home" : "videos"}_button_${slug.substring(1)}`}
      href={slug}
      aria-label={ordering}
      css={css`
        display: block;
      `}
    >
      <section>
        <div
          css={css`
            display: inline-block;
            width: 100%;
          `}
        >
          <div
            css={css`
              position: absolute;
              z-index: 100;
              top: 0;
              right: 15px;
              bottom: 0;
              left: 15px;
              background-color: rgba(0, 0, 0, 0.2);
              width: calc(100% - 30px);
              height: 100%;
            `}
          />
          <img
            loading='lazy'
            css={css`
              width: 100%;
            `}
            alt={alt}
            src={img}
          />
        </div>
        <div
          css={isHome ?
            css`
            position: absolute;
            z-index: 200;
            right: 30px;
            bottom: 15px;
            left: 30px;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
          `
            :
            css`
            position: absolute;
            z-index: 200;
            right: 30px;
            bottom: 0px;
            top: 0;
            left: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
          `
          }
        >
          {
            isHome ?
              <p
                css={css`
                margin: 0;
                width: 150px;
                color: white;
                font-size: 22px;
                font-weight: bold;
                line-height: 27px;
                text-shadow: 3px 5px 9px rgba(0, 0, 0, 0.82);
                text-transform: uppercase;

                ${[mq[1]]} {
                  font-size: 18px;
                }

                ${[mq[4]]} {
                  font-size: 12px;
                  line-height: 20px;
                }
              `}
              >
                {category}
              </p>
              :
              <div>

              </div>
          }
          {
            !isHome ?
              <img
                loading='lazy'
                css={css`
                color: white;
                text-shadow: 3px 5px 9px rgba(0, 0, 0, 0.82);
                font-size: 40px;
                ${[mq[2]]} {
                  font-size: 30px;
                }
                path {
                  color: inherit;
                  text-shadow: inherit;
                }
              `}
                src={playBtn}
                alt=""
              />
              :
              <img
                loading='lazy'
                css={css`
                color: white;
                text-shadow: 3px 5px 9px rgba(0, 0, 0, 0.82);
                font-size: 40px;
                ${[mq[2]]} {
                  font-size: 30px;
                }
                path {
                  color: inherit;
                  text-shadow: inherit;
                }
              `}
                src={arrowRight}
                alt=""
              />
          }
        </div>
      </section>
      {
        !isHome ?
          <div
            css={css`
            position: absolute;
            padding: 16px 16px 0 0px;
          `}
          >
            <p
              css={css`
              font-size: 18px;
              line-height: normal;
              letter-spacing: normal;
              color: #8c8d91;
              ${[mq[1]]} {
                font-size: 14px;
              }
            `}
            >
              {category}
            </p>
          </div>
          :
          <div>

          </div>
      }
    </a>
  )
}

VideoCategory.propTypes = {
  img: PropTypes.node.isRequired,
  category: PropTypes.string.isRequired,
}

export default VideoCategory
